<template>
	<div class="vertical-navbar">
		<el-menu class="el-menu-vertical" default-active="1" mode="vertical" :collapse="collapse" background-color="#202124" text-color="#fff" active-text-color="#15cf00">
			<el-menu-item index="1">
				<i class="el-icon-menu"></i>
				<span>Budgety</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
export default {
	data() {
		return {
			collapse: false,
		}
	},
}
</script>