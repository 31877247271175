<template>
  <div id="app">
		<el-row class="main-row">
			<Vnavbar />
			<router-view/>
		</el-row>
	
		<div v-show="updateExists" class="update-box">
			<p>Je dostupná nová verzia</p>
			<el-button @click="refreshApp">Aktualizovať</el-button>
		</div>
  </div>
</template>

<script>
import update from "@/pwa/update"

import Vnavbar from "@/components/layout/Vnavbar";

export default {
	components: {
		Vnavbar,
	},
	mixins: [update],
	
	// watch: {
	// 	updateExists: {
	// 		handler: function (newVal) {
	// 			if (newVal) {
	//
	// 			}
	// 		}
	// 	}
	// },
	mounted() {
	
	}
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: white;
	
	.update-box {
		position: fixed;
		bottom: 10px;
		left: 10px;
		background: #0E1013;
		padding: 20px;
		display: flex;
		flex-direction: column;
		border-radius: 15px;
		
		p {
			margin-top: 0;
		}
		
		.el-button {
			background: $primary;
			color: black;
			border: none;
		}
	}
	
	
	.el-loading-spinner .path {
		stroke: $primary;
	}
	
	.main-row {
		display: flex;
		flex-direction: row;
		
		.el-menu-vertical:not(.el-menu--collapse) {
			width: 200px;
			//min-height: 100vh;
			height: 100%;
		}
	}
}

</style>
